import classNames from "classnames";

import "./labeled_input.less";

/**
 * It renders a label tag and passed component with required props
 * Useful when we need to add a label and associate it with an input
 */

/*
Default Props
*/
LabeledInput.defaultProps = {
  alignment: "column",
};

function LabeledInput({
  component: InputComponent,
  wrapperClassName,
  label,
  innerRef,
  ...restProps
}) {
  const { id, required, alignment, description, size } = restProps;

  return (
    <div
      className={classNames(
        "ui-labeled-input",
        { "ui-labeled-input__row-aligned": alignment === "row" },
        wrapperClassName,
        `ui-labeled-input__${size}`
      )}
    >
      <label
        htmlFor={id}
        className={classNames("ui-labeled-input__label", {
          "is-required-field": required,
        })}
      >
        {label}
      </label>
      <InputComponent {...restProps} size={size} ref={innerRef} />
      {description && (
        <div className="ui-labeled-input__description">{description}</div>
      )}
    </div>
  );
}

export { LabeledInput as PureLabeledInput };
export default LabeledInput;
