import { useEffect } from "react";

import { errorNotification, successNotification } from "@/ui-kit";
import { proxy, useSnapshot } from "valtio";

const store = proxy({
  error: {
    message: "Error",
    description: "",
  },
  success: {
    message: "Success",
    description: "",
  },
});

export const reset = () => {
  store.error.description = "";
  store.success.description = "";
};

export const showError = (
  description = "Something went wrong",
  message = "Error"
) => {
  store.error = {
    description,
    message,
  };
};

export const showSuccess = (description: string, message = "Success") => {
  store.success = {
    description,
    message,
  };
};

type Props = {
  children: JSX.Element;
};

const NotificationHandler = ({ children }: Props) => {
  const { error, success } = useSnapshot(store);

  useEffect(() => {
    if (error.description) {
      errorNotification(error);
    }
    return () => {
      reset();
    };
  }, [error]);

  useEffect(() => {
    if (success.description) {
      successNotification(success);
    }
    return () => {
      reset();
    };
  }, [success]);

  return <>{children}</>;
};

export default NotificationHandler;
