import { fetcher } from "@/ve-utils/api_call";
import { mutate } from "swr";

export type Mutate = typeof mutate;

export const apiCallSWR: typeof fetcher = async (url, options) => {
  let full_url = url;
  if (Array.isArray(full_url)) {
    const [url, params] = full_url;
    full_url = url + "?" + params;
  }
  const { ok, status, data } = await fetcher(full_url, options);
  // If the status code is not in the range 200-299 or status is not 404,
  // we still try to parse and throw it.
  if (!ok && status !== 404) {
    const error: any = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    error.data = data;
    error.status = status;
    throw error;
  }

  return data;
};

export const mutateSWR = async (user_key: string, mutate: Mutate) => {
  await mutate((key) =>
    Array.isArray(key) ? key[0] === user_key : key === user_key
  );
};

export const SWRCache = new Map();

export const default_error_description = "Something went wrong";
