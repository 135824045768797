import useSWR, { mutate } from "swr";
import { API } from "@/utils/api_config";
import { fetcher } from "@/ve-utils/api_call";
import { Profile } from "./types";
import { setMarketplaceClientId } from "@/models/root/user";
import useSWRImmutable from "swr/immutable";
import { makeQueryParams } from "@/ve-utils/common";
import { errorNotification, successNotification } from "@/ui-kit";

export const useExistingFinanceApplications = () => {
  const url = API.root.getExistingFinanceApplications;
  const { data, error, isLoading } = useSWR(url);

  return {
    financeApplications: data?.data,
    isLoading,
    error,
  };
};

export const reloadProfile = () => {
  const url = API.root.getProfile;
  return mutate(url);
};

export const useProfile = () => {
  const url = API.root.getProfile;
  const {
    data,
    error,
    isLoading,
    mutate: reloadProfile,
  } = useSWRImmutable(url);
  const profile = (data?.data || {}) as Profile;
  const companyUuid = profile?.company?.uuid;
  const isEwsAdmin = profile?.neobank_role === "ews_admin";
  setMarketplaceClientId(profile?.marketplace?.client_id);

  return {
    profile,
    isLoading,
    error,
    reloadProfile,
    isEwsAdmin,
    companyUuid,
  };
};

export const updatePreference = async (data: boolean) => {
  await fetcher(API.root.updatePreference, {
    method: "put",
    body: JSON.stringify({
      preferences: {
        whatsapp: data,
      },
    }),
  });
};

export const updateInsightsApplication = async (data: any) => {
  await fetcher(API.finance.createInsightsApplication, {
    method: "put",
    body: JSON.stringify({
      insight_application: data,
    }),
  });
};

export const sendEmailVerificationLink = async () => {
  const url = API.root.sendEmailVerification;
  const { ok, data } = await fetcher(url, {
    method: "post",
  });
  return { ok, data: data?.data };
};

export const createFinanceApplication = async () => {
  const { ok, data } = await fetcher(API.finance.createFinanceApplication, {
    method: "post",
    body: JSON.stringify({
      application_type: "finance",
    }),
  });
  return { ok, data: data?.data };
};

export const linkUser = async (params: any) => {
  const { ok, data, status } = await fetcher(API.root.linkApplication, {
    method: "post",
    body: JSON.stringify(params),
  });
  if (!ok && data?.error) {
    errorNotification({
      message: "Error",
      description: data?.error?.title,
    });
  }
  return { ok, data: data, status };
};

export const updateUserPhone = async (payload: any) => {
  const params = makeQueryParams(payload, []);
  const url = `${API.root.updateUserPhone}?${params}`;

  const { ok, data } = await fetcher(url, { method: "post" });

  if (!ok)
    errorNotification({
      message: data?.error?.title || "Something went wrong",
    });
  else successNotification({ message: "OTP sent successfully!" });

  return { ok, data };
};

export const verifyUserupdatedPhone = async (payload: any) => {
  const params = makeQueryParams(payload, []);
  const url = `${API.root.verifyUserUpdatedPhone}?${params}`;

  const { ok, data } = await fetcher(url, { method: "post" });

  if (!ok)
    errorNotification({
      message: data?.error?.title || "Something went wrong",
    });
  else successNotification({ message: "Phone updated successfully!" });

  return { ok, data };
};

export const updateUserEmail = async (payload: any) => {
  const params = makeQueryParams(payload, []);
  const url = `${API.root.updateUserEmail}?${params}`;

  const { ok, data } = await fetcher(url, { method: "post" });

  if (!ok)
    errorNotification({
      message: data?.error?.title || "Something went wrong",
    });
  else
    successNotification({
      message: "Success",
      description: "Email verification link has been successfully sent",
    });

  return { ok, data };
};
