import * as React from "react";
import { ErrorWarningFillIcon } from "@Velocity-Engineering/ve-icons";

import Select from "./select";
import Field from "../field/field";

import "./select.less";

const FormikSelect = React.forwardRef(
  (
    { name, validate, fast, onSelect: $onSelect, $onBlur, ...restProps },
    ref
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value, onBlur }, form }) => {
        const { touched, errors } = form;
        const error = touched[name] && errors[name];

        return (
          <div className="ui-formik-select-container">
            <Select
              ref={ref}
              name={name}
              error={error}
              value={value}
              onFocus={() => {
                form.setFieldTouched(name, true);
              }}
              onBlur={(event) => {
                onBlur(event);
                $onBlur && $onBlur(event);
              }}
              onSelect={(value) => {
                form.setFieldValue(name, value);
                $onSelect && $onSelect(value);
              }}
              {...restProps}
            />
            {error && (
              <p className="ui-formik-select-container__error-message">
                <ErrorWarningFillIcon />
                {error}
              </p>
            )}
          </div>
        );
      }}
    </Field>
  )
);

export default FormikSelect;
