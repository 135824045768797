import { RouteObject } from "react-router-dom";

const routes: RouteObject = {
  path: "/buyer-credit-score-check",
  children: [
    {
      index: true,
      lazy: () =>
        import(
          "@/pages/payments/pages/buyer_credit_score_check/buyer_credit_score_check"
        ),
    },
    {
      path: "update-buyer-details",
      lazy: () =>
        import(
          "@/pages/payments/pages/buyer_credit_score_check/components/update_buyer_details"
        ),
    },
    {
      path: "score-check-success",
      lazy: () =>
        import(
          "@/pages/payments/pages/buyer_credit_score_check/components/score_check_success"
        ),
    },
  ],
};

export default routes;
