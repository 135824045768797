import { Navigate, RouteObject, redirect } from "react-router-dom";

import auth_routes from "@/routes/auth";
import buyer_payment_routes from "@/routes/buyer_requests";
import buyer_credit_score_check_routes from "@/routes/buyer_credit_score_check";
import payment_routes from "@/routes/payments";
import { errorElement } from "./utils";

import Cards from "@/pages/cards/cards";
import Insights from "@/pages/insights/insights";
import PageNotFound from "@/pages/page_not_found/page_not_found";
import financeAccountActiveRoutes from "./finance/accountActive";
import financeRoutes from "./finance";
import pdcRoutes from "./pdc";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/product" />,
    errorElement,
  },
  {
    path: "/vendor-self-registration",
    lazy: () => import("@/pages/vendor/self_registration"),
    errorElement,
  },
  {
    path: "/buyer-payments",
    loader: ({ request }) => {
      const url = new URL(request.url);
      const paymentLinkCode = url.searchParams.get("payment_link");
      const isUpiEnabled = url.searchParams.get("upi");
      return redirect(`/buyer-payment/${paymentLinkCode}/${isUpiEnabled}`);
    },
  },
  buyer_payment_routes,
  buyer_credit_score_check_routes,
  {
    path: "/update_email",
    lazy: () => import("@/pages/auth/signup/verify_email"),
    errorElement,
  },
  {
    path: "/verify_email",
    lazy: () => import("@/pages/auth/signup/verify_email"),
    errorElement,
  },
  {
    path: "/insights_subscription",
    lazy: () => import("@/pages/insights_new/insights_new"),
    errorElement,
  },
  {
    path: "/insights_subscription_form",
    lazy: () => import("@/pages/insights_subscription/insights_subscription"),
    errorElement,
  },
  {
    path: "/otp_verification",
    lazy: () => import("@/pages/insights_subscription/otp_verification"),
    errorElement,
  },
  {
    path: "/payment-link/:id/approve",
    lazy: () =>
      import("@/pages/payments/pages/payment_requests/payment_link_approve"),
    errorElement,
  },
  {
    path: "/kyc-digio-aadhaar",
    lazy: () => import("@/pages/payments/pages/kyc/digio/aadhaar"),
  },
  {
    path: "/digital_pdc",
    lazy: () => import("@/pages/payments/pages/digital_pdc"),
    errorElement,
  },
  {
    path: "/ews-dashboard",
    lazy: () => import("@/pages/payments/pages/ews_dashboard"),
    errorElement,
  },
  {
    path: "/marketplace/indiamart",
    lazy: () => import("@/pages/auth/marketplace_login"),
    errorElement,
  },
  {
    path: "/marketplace/connect",
    lazy: () => import("@/pages/auth/marketplace_login"),
    errorElement,
  },
  pdcRoutes,
  auth_routes,
  {
    path: "*",
    lazy: () => import("@/layouts/after_login"),
    errorElement,
    children: [
      {
        path: "link_application",
        lazy: () => import("@/pages/link_application/link_application"),
        errorElement,
      },
      {
        path: "product",
        lazy: () => import("@/pages/product/choose_product"),
        errorElement,
      },
      {
        path: "settings",
        lazy: () => import("@/pages/settings/settings"),
        errorElement,
      },
      {
        path: "cards/*",
        element: <Cards />,
      },
      {
        path: "insights/*",
        element: <Insights />,
      },
      payment_routes,
      financeRoutes,
      financeAccountActiveRoutes,
    ],
  },
  {
    path: "404",
    element: <PageNotFound />,
  },
];

export default routes;
