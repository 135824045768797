import { useEffect } from "react";
import { useProfile } from "@/swr/user";
import { isProduction } from "@/ve-utils/common";
import TagManager from "react-gtm-module";
import { addUserAttribute } from "@/utils/common_utils";
import ReactGA from "react-ga";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const usePopulateDataLayerHook = (jwt: any): [] => {
  const { profile, isLoading } = useProfile();
  const enableTracking = jwt ? jwt?.client !== "ghost" : !!jwt;
  const isProd = isProduction();

  if (isProd) {
    ReactGA?.initialize("UA-177433365-1");
  }

  useEffect(() => {
    if (enableTracking && profile && !isLoading) {
      const {
        phone,
        email,
        full_name,
        uuid: user_uuid,
        company,
        created_at: signup_date,
        marketplace,
      } = profile || {};
      const company_uuid = company?.uuid;
      const marketplaceName = marketplace?.name;
      const dataLayerProperties = {
        userId: jwt.uid,
        mail: jwt.uid,
        phone: phone,
        userName: full_name,
        user_uuid,
        company_uuid,
        signup_date,
        marketplace: marketplaceName
      };

      // populate hotjar attributes
      if (company_uuid) {
        addUserAttribute(user_uuid, {
          email,
          full_name,
          company_uuid,
        });
      }

      if (window?.dataLayer) {
        window?.dataLayer.push(dataLayerProperties);
      } else {
        const config = {
          gtmId: "GTM-KF9PN7H",
          auth: isProd ? "vg0uRXj9RXX0zSJpmu6m5Q" : "AmZId3t18OGjbGScLBARng",
          preview: isProd ? "env-1" : "env-2",
          dataLayer: dataLayerProperties,
        };

        TagManager.initialize(config);
      }
    }
  }, [profile]);

  return [];
};
