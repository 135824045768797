import { Mode } from "./config";

export const MICRO_FE_PRODUCTS = ["cards", "insights"] as const;

export type Product = (typeof MICRO_FE_PRODUCTS)[number];

type Hosts = {
  [key in Product]: {
    [key in Mode]: string;
  };
};
const hosts: Hosts = {
  cards: {
    staging: "cards-web.stagingvelocity.in",
    production: "cards-web.velocity.in",
    development: "localhost:8001",
  },
  insights: {
    staging: "insights.stagingvelocity.in",
    production: "insights-web.velocity.in",
    development: "localhost:8003",
  },
};

export const getHost = (product: Product) => {
  if (MICRO_FE_PRODUCTS.includes(product)) {
    return "//" + hosts[product][import.meta.env.MODE as Mode];
  }
  return "";
};
