import { useEffect } from "react";
import { setDeviceHeader } from "@/models/root/device";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

export const useSetDeviceHeader = (): [] => {
  const { xs: isMobile } = useBreakpoint();

  useEffect(() => {
    if (typeof isMobile === "boolean") {
      setDeviceHeader({ isMobile });
    }
  }, [isMobile]);

  return [];
};
