import { proxy } from "valtio";

const initialState = {
  header: undefined,
};

export const device_store = proxy<{
  header?: "desktop" | "mobile";
}>(initialState);

export const setDeviceHeader = ({ isMobile }: { isMobile: boolean }) => {
  device_store.header = isMobile ? "mobile" : "desktop";
};
