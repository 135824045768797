export const AppImages: Record<string, string> = {
  SHOPIFY: "https://d1pejaumv7umok.cloudfront.net/connect_icons/shopify.svg",
  AMAZON: "https://d1pejaumv7umok.cloudfront.net/connect_icons/amazon.svg",
  AMAZON_ADS: "https://d1pejaumv7umok.cloudfront.net/amazon_ads.png",
  RAZORPAY: "https://d1pejaumv7umok.cloudfront.net/connect_icons/razorpay.svg",
  WOOCOMMERCE: "https://cdn.worldvectorlogo.com/logos/woocommerce-1.svg",
  GOOGLE_ADS: "https://assets-ve.s3.us-east-2.amazonaws.com/google-ads2.png",
  FACEBOOK: "https://d1pejaumv7umok.cloudfront.net/facebook.png",
  CASHFREE: "https://d1pejaumv7umok.cloudfront.net/cashfree.jpeg",
  PAYTM: "https://d1pejaumv7umok.cloudfront.net/paytm.jpeg",
  PAYU: "https://d1pejaumv7umok.cloudfront.net/payu.png",
  EASY_OPS: "https://d1pejaumv7umok.cloudfront.net/easyops.png",
  UNICOMMERCE: "https://d1pejaumv7umok.cloudfront.net/unicommerce.jpeg",
  GST: "https://d1pejaumv7umok.cloudfront.net/gst.png",
  FLIPKART: "https://d1pejaumv7umok.cloudfront.net/connect_icons/flipkart.jpeg",
  EASY_ECOM_FLIPKART:
    "https://d1pejaumv7umok.cloudfront.net/connect_icons/flipkart.jpeg",
  MYNTRA: "https://d1pejaumv7umok.cloudfront.net/connect_icons/myntra.svg",
  GOOGLE_ANALYTICS: "https://d1pejaumv7umok.cloudfront.net/analytics.svg",
  MEESHO: "https://assets-ve.s3.us-east-2.amazonaws.com/meesho_logo.png",
  FIRST_CRY: "https://d1pejaumv7umok.cloudfront.net/first_cry.png",
};

export const APPS = ["GOOGLE_ADS", "FACEBOOK", "AMAZON_ADS"];

export const SALE_APPS = ["SHOPIFY", "AMAZON", "WOOCOMMERCE"];

export const GOOGLE_ANALYTICS = ["GOOGLE_ANALYTICS"];

export const OFFLINE_SALE_APPS = ["MYNTRA"];

export const OTHER_APPS = ["GST"];

export const TOTAL_APPS = [
  ...APPS,
  ...SALE_APPS,
  ...OTHER_APPS,
  ...GOOGLE_ANALYTICS,
];

export const ANALYTICS_APPS = {
  GOOGLE_ANALYTICS: "GOOGLE_ANALYTICS",
};

export const ALL_SALES_APPS = [...SALE_APPS, ...OFFLINE_SALE_APPS];

export const APP_DATA: Record<string, Record<string, string>> = {
  SHOPIFY: {
    name: "Shopify",
    key: "SHOPIFY",
    id: "shopify",
    image: AppImages["SHOPIFY"],
    cta: "CONNECT",
    description:
      "Import data from your Shopify store and analyse your sales, order metrics. Measure AOV and Repeat %.",
  },
  AMAZON: {
    name: "Amazon",
    key: "AMAZON",
    id: "amazon",
    image: AppImages["AMAZON"],
    cta: "CONNECT",
    description:
      "Import data from your Amazon Marketplace and analyse your revenue metrics. Measure AOV and Shipping cost.",
  },
  AMAZON_ADS: {
    name: "Amazon Ads",
    key: "AMAZON_ADS",
    id: "amazon_ads",
    image: AppImages["AMAZON_ADS"],
    description:
      "Import data from your Amazons Ads and analyze your ad metrics.",
    cta: "CONNECT",
  },
  GOOGLE_ADS: {
    name: "Google Ads",
    id: "google_ads",
    key: "GOOGLE_ADS",
    image: AppImages["GOOGLE_ADS"],
    description:
      "Import data from your Google Ads and analyze your ad metrics.",
    cta: "CONNECT",
  },
  GOOGLE_ANALYTICS: {
    name: "Google Analytics",
    id: "google_analytics",
    key: "GOOGLE_ANALYTICS",
    image: AppImages["GOOGLE_ANALYTICS"],
    cta: "CONNECT",
  },
  FACEBOOK: {
    name: "Facebook Ads",
    id: "facebook",
    key: "FACEBOOK",
    image: AppImages["FACEBOOK"],
    description:
      "Import data from your Facebook Ads and analyze your ad metrics.",
    cta: "CONNECT",
  },
  WOOCOMMERCE: {
    name: "Woocommerce",
    id: "woocommerce",
    key: "WOOCOMMERCE",
    image: AppImages["WOOCOMMERCE"],
    cta: "CONNECT",
    description:
      "Import data from your Woocommerce store and analyse your revenue metrics. Measure AOV and Repeat %.",
  },
  GST: {
    name: "GST",
    id: "gst",
    key: "GST",
    image: AppImages["GST"],
    cta: "CONNECT",
  },
  MYNTRA: {
    name: "Myntra",
    id: "myntra",
    key: "MYNTRA",
    image: AppImages["MYNTRA"],
    cta: "VIEW INSTRUCTIONS",
  },
  EASY_ECOM_FLIPKART: {
    name: "Flipkart",
    id: "easyecom",
    key: "EASY_ECOM_FLIPKART",
    image: AppImages["EASY_ECOM_FLIPKART"],
    cta: "CONNECT",
  },
};
