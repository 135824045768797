import { getAssetURL } from "@/ve-utils/common";
import { Link } from "react-router-dom";

import { GreenButton } from "@/ui-kit";

import styles from "./page_not_found.module.less";
import Title from "antd/lib/typography/Title";

function PageNotFound() {
  return (
    <div className={styles.pageNotFoundContainer}>
      <img
        src={getAssetURL("404.svg")}
        alt="page not dfound"
        className={styles.image}
      />
      <br />
      <Title level={2}>Oops! Something went wrong.</Title>
      <Title level={5}>The page you requested could not be found.</Title>
      <br />
      <Link to="/product">
        <GreenButton>GO BACK HOME</GreenButton>
      </Link>
    </div>
  );
}

export default PageNotFound;
