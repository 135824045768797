import { Radio } from "antd";
import classnames from "classnames";

import "./radio.less";

function RadioCustom(props) {
  const { className, size, ...restProps } = props;

  return (
    <Radio.Group
      className={classnames(
        className,
        "ui-radio-container",
        "ui-radio",
        `ui-radio--${size}`
      )}
      size={size}
      {...restProps}
    />
  );
}

export default RadioCustom;
