import { query, search } from "@/utils";
import { jwt } from "@/ve-utils/cookie_utils";

const pathname = location.pathname;

// If any cookie coming from these bypass pathnames, then the session will be overridden to the new cookies.
const PATHNAMES_TO_BYPASS = ["/auth/magic", "/auth/ghost", "/auth/web"];

const getAuthRedirectionLinkFromPathname = () => {
  return pathname !== "/" && pathname !== "/login"
    ? `/auth/login?next=${pathname}${search}`
    : "/auth/login";
};

export const auth = () => {
  let redirect_url;

  if (query.redirect) {
    location.href = decodeURIComponent(query.redirect);
    return;
  }

  // we should support to bypass the magic/ghost link when new token is passed
  // this is required for partnerships to work as expected.
  const isEligibleForBypass =
    query.token && PATHNAMES_TO_BYPASS.includes(pathname);

  if (!isEligibleForBypass && jwt) {
    redirect_url = query.next ? query.next : "/product";
  }

  return redirect_url;
};

// checks if the user is still logged in (jwt is present) and
// if not the user is redirected to the login page
export const auth_guard = () => {
  let redirect_url;
  if (!jwt) {
    redirect_url = getAuthRedirectionLinkFromPathname();
  }
  return redirect_url;
};
