import { RouteObject } from "react-router-dom";

const financeRoutes: RouteObject = {
  lazy: () => import("@/pages/finance/layout"),
  path: "finance",
  children: [
    {
      path: "overview",
      lazy: () => import("@/pages/finance/pages/overview/overview"),
    },
    {
      path: "payments",
      lazy: () => import("@/pages/finance/pages/payments/payments"),
    },
    {
      path: "payable",
      lazy: () => import("@/pages/finance/pages/ledger/ledger"),
    },
    {
      path: "revenue",
      lazy: () => import("@/pages/finance/pages/revenue/revenue"),
    },
    {
      path: "outstanding",
      lazy: () => import("@/pages/finance/pages/transactions/transactions"),
    },
    {
      path: "repayment",
      lazy: () => import("@/pages/finance/pages/repayments/repayments"),
    },
    {
      path: "integrations",
      lazy: () => import("@/pages/finance/pages/integrations/integrations"),
    },
    {
      path: "collections",
      lazy: () => import("@/pages/finance/pages/collections/collections"),
    },
    {
      path: "settings",
      lazy: () => import("@/pages/settings/settings"),
    },
  ],
};
export default financeRoutes;
