import { useEffect } from "react";

import { registerMicroApps, start } from "qiankun";
import { getHost } from "@/utils/get_host";

import styles from "@/layouts/after_login/after_login.module.less";

function Insights() {
  useEffect(() => {
    registerMicroApps([
      {
        name: "insights app",
        entry: getHost("insights"),
        container: "#child",
        activeRule: "/insights",
      },
    ]);
    start();
  }, []);

  return <div className={styles.children} id="child"></div>;
}

export default Insights;
