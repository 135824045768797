import { useEffect } from "react";
import { registerMicroApps, start } from "qiankun";

import { getHost } from "@/utils/get_host";

import styles from "@/layouts/after_login/after_login.module.less";

function Cards() {
  useEffect(() => {
    registerMicroApps([
      {
        name: "cards app",
        entry: getHost("cards"),
        container: "#child",
        activeRule: "/cards",
      },
    ]);
    start();
  }, []);

  return <div className={styles.children} id="child"></div>;
}

export default Cards;
