/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { notification } from "antd";
import { API } from "@/utils/api_config";
import { SignInType, VerifyOtpType } from "./types";

export const getPartnerData = async (clientId: string) => {
  const url = API.finance.getMarketplaceConfig;

  return axios.get(`${url}?client_id=${clientId}`);
};

export const signIn = async (
  payload: SignInType,
  client: string | undefined
) => {
  const url = API.root.signIn;

  return axios.post(url, payload, {
    headers: {
      client: client === "magic" ? "magic" : "web",
    },
  });
};

export const verifyOtp = async (
  payload: VerifyOtpType,
  client: string | undefined
) => {
  const url = API.root.verifyOtp;

  return axios.post(url, payload, {
    headers: {
      client: client === "magic" ? "magic" : "web",
    },
  });
};

export const loginWithSso = async (payload: any, provider: string) => {
  const url = getSsoProviderUrl(provider);

  return axios.post(url, payload, {
    headers: {
      client: "web",
    },
  });
};

export const getUserMarketplaces = async (
  payload: any,
  client: string | undefined
) => {
  const url = API.root.getUserMarketplaces;

  return axios
    .post(url, payload, {
      headers: {
        client: client === "magic" ? "magic" : "web",
      },
    })
    .then((resp) => resp)
    .catch((error) => {
      notification["error"]({
        message: "Error",
        description:
          error?.response?.data?.error?.title || "Something went wrong",
      });
    });
};

export const forgotPassword = async (payload: {
  phone?: string;
  email?: string;
  marketplace_client_id: string;
}) => {
  const url = API.root.forgotPassword;

  return axios
    .post(url, payload)
    .then((resp) => resp)
    .catch((error) => {
      notification["error"]({
        message: "Forgot password error",
        description:
          error?.response?.data?.error?.title || "Something went wrong",
      });
    });
};

export const signUp = async (payload: any) => {
  const url = API.root.signup;

  return axios
    .post(url, payload)
    .then((resp) => resp)
    .catch((error) => {
      const errorObj = error?.response?.data?.error;
      notification["error"]({
        message: errorObj?.title || "Signup Error",
        description: errorObj?.message?.message || "Something went wrong",
      });
    });
};

export const resetPassword = async (payload: any) => {
  const url = API.root.resetPassword;

  return axios
    .put(url, payload)
    .then((resp) => resp)
    .catch((error) => {
      const errorObj = error?.response?.data?.error;
      notification["error"]({
        message: errorObj?.title || "Signup Error",
        description: errorObj?.message?.message || "Something went wrong",
      });
    });
};

export const verifyResetToken = async ({ reset_password_token }: any) => {
  const url = API.root.verifyResetToken;
  const payload = { reset_password_token };

  return axios
    .post(url, payload)
    .then((resp) => resp)
    .catch((error) => {
      const errorObj = error?.response?.data?.error;
      notification["error"]({
        message: errorObj?.title || "Token Error",
        description: "Your token is invalid. Redirecting to login",
      });
    });
};

const getSsoProviderUrl = (provider: string) => {
  switch (provider) {
    case "myntra":
      return API.root.loginWithMyntra;
    default:
      return API.root.loginWithSokudo;
  }
};

export const registerUser = async (payload: any) => {
  const url = API.root.registerUser;

  return axios
    .post(url, payload)
    .then((resp) => resp)
    .catch((error) => {
      const errorObj = error?.response?.data?.error;
      notification["error"]({
        message: errorObj?.title || "Error",
        description:
          errorObj?.message?.message ||
          "Something went wrong. Please try again",
      });
    });
};

export const sendOtp = async (payload: any) => {
  const url = API.root.sendOtp;

  return axios
    .post(url, payload)
    .then((resp) => resp)
    .catch((error) => {
      const errorObj = error?.response?.data?.error;
      notification["error"]({
        message: errorObj?.title || "Error",
        description:
          errorObj?.message?.message ||
          "Sending OTP failed! Try after some time",
      });
    });
};

export const validateOtp = async (payload: any) => {
  const url = API.root.validateOtp;

  return axios
    .post(url, payload)
    .then((resp) => resp)
    .catch((error) => {
      const errorObj = error?.response?.data?.error;
      notification["error"]({
        message: errorObj?.title || "Error",
        description:
          errorObj?.message?.message ||
          "Sending OTP failed! Try after some time",
      });
    });
};
