import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { useSearchParams, Link } from "react-router-dom";
import { errorNotification, Loader } from "@/ui-kit";

import { queryParamsToObject } from "@/utils";
import { loginWithSso } from "@/swr/auth";
import { setJWTFromResponseHeaders } from "@/ve-utils/api_call";

const { Text } = Typography;

const MYNTRA_SSO_CIDX = "Velocity-c808fb9a-a015-404d-95c9-558f4a156920";

const getLoginErrorRedicrectUrl = (provider: string) => {
  switch (provider) {
    case "myntra":
      return "https://myntra.velocity.in/auth/login";
    default:
      return "/auth/login";
  }
};

function SsoAuthenticator() {
  const [isError, setIsError] = useState<boolean>(false);
  const [provider, setProvider] = useState<string>("");
  const [searchParams] = useSearchParams();
  const query = queryParamsToObject(searchParams);

  const apiCall = async () => {
    if (query.cidx == MYNTRA_SSO_CIDX) {
      setProvider("myntra");
    } else {
      setIsError(true);
      setProvider("");
      return;
    }

    await loginWithSso(query, provider)
      .then((response) => {
        setJWTFromResponseHeaders(response.headers);
        window.location.href = "/";
      })
      .catch((error) => {
        const errorObj = error.response?.data?.title;
        errorNotification({
          message: "Sign In error",
          description: errorObj ? errorObj : "Something went wrong",
        });
        setIsError(true);
      });
  };

  useEffect(() => {
    apiCall();
  }, []);

  const redirectUrl = getLoginErrorRedicrectUrl(provider);
  return isError ? (
    <Text>
      Error logging in. Please <Link to={redirectUrl}>Try Again</Link>
    </Text>
  ) : (
    <Loader text="Authenticating user" />
  );
}

export default SsoAuthenticator;
