import classnames from "classnames";

import "./loader.less";

type Props = {
  size?: "default" | "medium" | "small";
  showText?: boolean;
  text?: string;
  fillSpace?: boolean;
};

function Loader({
  size = "default",
  showText = true,
  text = "Loading....",
  fillSpace = true,
}: Props) {
  return (
    <div
      className={classnames(
        "ui-loader-container",
        `ui-loader-container--${size}`,
        !fillSpace ? "ui-loader-fix-width" : undefined
      )}
    >
      <div className="ui-loader-container__loader" />
      {showText && (
        <div className="ui-loader-container__text">{text || "Loading...."}</div>
      )}
    </div>
  );
}

export default Loader;
