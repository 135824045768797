import * as React from "react";

import Radio from "./radio";
import Field from "../field/field";

const FormikRadioGroup = React.forwardRef(
  ({ name, validate, fast, onChange: $onChange, ...restProps }, ref) => (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value }, form }) => {
        const { touched, errors } = form;
        const error = touched[name] && errors[name];

        return (
          <Radio
            ref={ref}
            name={name}
            error={error}
            value={value}
            onFocus={() => {
              form.setFieldTouched(name, true);
            }}
            onChange={(e) => {
              form.setFieldValue(name, e.target.value);
              $onChange && $onChange(e.target.value);
            }}
            {...restProps}
          />
        );
      }}
    </Field>
  )
);

export default FormikRadioGroup;
