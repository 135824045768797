import { RouteObject } from "react-router-dom";

const routes: RouteObject = {
  lazy: () => import("@/pages/payments/layout"),
  path: "payments",
  children: [
    {
      path: "get-started",
      lazy: () => import("@/pages/payments/pages/get_started"),
    },
    {
      path: "invoices",
      lazy: () => import("@/pages/payments/pages/invoices/invoices"),
    },
    {
      path: "customers",
      lazy: () => import("@/pages/payments/pages/customers/vendor_customers"),
    },
    {
      path: "payments",
      lazy: () => import("@/pages/payments/pages/payouts"),
    },
    {
      path: "history",
      lazy: () =>
        import("@/pages/payments/pages/payment_history/payment_history"),
    },
    {
      path: "vendors",
      lazy: () => import("@/pages/payments/pages/vendors/vendors"),
      children: [
        {
          index: true,
          lazy: () =>
            import("@/pages/payments/pages/vendors/components/vendor_list"),
        },
        {
          path: ":vendorId/:vendorUUID",
          lazy: () =>
            import(
              "@/pages/payments/pages/vendors/components/vendor_details/vendor_details"
            ),
        },
      ],
    },
    {
      path: "accounts",
      lazy: () => import("@/pages/payments/pages/accounts/accounts"),
    },
    // {
    //   path: "approvals",
    //   lazy: () => import("@/pages/payments/pages/approvals"),
    // },
    {
      path: "admin",
      lazy: () => import("@/pages/payments/pages/admin/Admin"),
    },
    {
      path: "payments-requests",
      lazy: () => import("@/pages/payments/pages/payment_requests"),
    },
  ],
};

export default routes;
