import { config } from "./config";

const {
  CURRENT_ENV,
  HOST,
  WEBSITE_HOST,
  NEOBANK_HOST,
  MIXPANEL_TOKEN,
  AWS_COLLECTIONS_HOST,
  CASHFREE_SDK,
  KYC_SDK,
  JUSPAY_HOST,
} = config;

export const API = {
  root: {
    signIn: `${HOST}/api/v1/users/login`,
    verifyOtp: `${HOST}/api/v1/users/verify-otp`,
    loginWithSokudo: `${HOST}/api/v1/users/login-with-sokudo`,
    loginWithMyntra: `${HOST}/api/v1/users/login-with-myntra`,
    getUserMarketplaces: `${HOST}/api/v1/users/marketplaces`,
    signup: `${HOST}/api/v1/users/`,
    changePassword: `${HOST}/api/v1/users/password`,
    verifyEmail: `${HOST}/api/v1/users/verify_email`,
    verifyUpdatedEmail: `${HOST}/api/v1/users/update-email`,
    forgotPassword: `${HOST}/api/v1/users/password/forgot`,
    resetPassword: `${HOST}/api/v1/users/password/reset`,
    verifyResetToken: `${HOST}/api/v1/users/password/verify-token`,
    sendEmailVerification: `${HOST}/api/v1/users/resend_verification_email`,
    linkApplication: `${HOST}/api/v1/users/add_user`,
    decryptUserData: `${HOST}/api/public/applications/indiamart-decrypt-register`,

    registerUser: `${HOST}/api/public/applications/register`,
    sendOtp: `${HOST}/api/public/applications/send-otp`,
    validateOtp: `${HOST}/api/public/applications/verify-otp`,

    //Profile
    getProfile: `${HOST}/api/v1/users/profile`,
    getExistingFinanceApplications: `${HOST}/api/v1/finance_applications`,
    getCompany: `${HOST}/api/v1/company/`,
    updatePreference: `${HOST}/api/v1/users/update_preferences`,
    invite: `${NEOBANK_HOST}/api/v1/invites`,
    publicInvite: `${NEOBANK_HOST}/public/`,
    updateUserPhone: `${HOST}/api/v1/users/profile/update-phone`,
    verifyUserUpdatedPhone: `${HOST}/api/v1/users/profile/verify-updated-phone`,
    updateUserEmail: `${HOST}/api/v1/users/profile/update-email`,

    //integrations
    getApplicationIntegrations: (applicationId: string) =>
      `${HOST}/api/borrower/v1/applications/${applicationId}/integrations`,
    getIntegrations: `${HOST}/api/v1/integrations`,
    getInternalReports: `${HOST}/api/v1/integrations/internal-reports`,
    discardIntegration: (integrationId: string, discardReason: string) =>
      `${HOST}/api/v1/integration_accounts?id=${integrationId}&reason=${discardReason}`,
    publicBuyerRequest: (paymentLinkCode: string) =>
      `${NEOBANK_HOST}/public/payment_requests/payment_links/${paymentLinkCode}`,
    publicChangeBuyerRequestMode: `${NEOBANK_HOST}/public/payment_requests/payment_links/mode`,
  },
  finance: {
    // Users
    signup: `${HOST}/api/v1/users/`,
    changePassword: `${HOST}/api/v1/users/password`,
    sendEmailVerificationLink: `${HOST}/api/v1/users/resend_verification_email`,
    createFinanceApplication: `${HOST}/api/v1/applications/initiate-rbf-application`,
    getMagicLink: (application_uuid: string) =>
      `${HOST}/api/v1/applications/${application_uuid}/magic-link`,

    //Profile
    getProfile: `${HOST}/api/v1/users/profile`,
    getCompany: `${HOST}/api/v1/company`,
    getCompanyGst: (pan: string) =>
      `${HOST}/api/v1/company/gst-integration?pan=${pan}`,
    getCompanyEntityDetails: `${HOST}/api/v1/integrations/entity-details`,

    // marketplace
    getMarketplaceConfig: `${HOST}/public/marketplace`,

    // Offers
    fetchOffersList: `${HOST}/api/borrower/v1/offers`,
    acceptOffer: (offerId: string) =>
      `${HOST}/api/borrower/v1/offers/${offerId}/accept`,

    // Indicative Offers
    getIndicativeOffers: `${HOST}/api/borrower/v1/indicative-offers`,

    //Onboarding
    createApplication: `${HOST}/api/borrower/v1/applications`,
    applicationDocuments: (application_uuid: string) =>
      `${HOST}/api/borrower/v1/applications/${application_uuid}/documents`,
    updateFinanceApplicationState: (application_uuid: string) =>
      `${HOST}/api/borrower/v1/applications/${application_uuid}/update-state`,
    getApplicant: `${HOST}/api/v1/onboarding/applicant`,
    updateApplicant: `${HOST}/api/v1/onboarding/applicant`,
    getApplication: `${HOST}/api/v1/onboarding`,
    getDocuments: (application_uuid: string) =>
      `${HOST}/api/borrower/v1/applications/${application_uuid}/documents`,
    getDocumentSignatures: (application_uuid: string, document_uuid: string) =>
      `${HOST}/api/borrower/v1/applications/${application_uuid}/documents/${document_uuid}/esign-signatures`,
    getBanks: `${HOST}/api/borrower/v1/banks`,
    updateApplication: `${HOST}/api/v1/onboarding`,
    updateApplicationData: (application_uuid: string) =>
      `${HOST}/api/borrower/v1/applications/${application_uuid}`,
    getDocumentDetails: `${HOST}/public/document-types`,
    uploadDocument: (application_uuid: string) =>
      `${HOST}/api/v1/onboarding/documents?application_uuid=${application_uuid}`,
    uploadMultipleDocuments: `${HOST}/api/v1/application_documents`,
    deleteDocument: `${HOST}/api/v1/application_documents`,
    updateApplicationState: `${HOST}/api/v1/finance_applications/update-state`,
    updateResanctionApplicationState: `${HOST}/api/v1/resanction_applications/update-state`,
    updateFinanceApplicationInsightsEligibility: `${HOST}/api/v1/finance_applications/insights-eligibility`,

    getDirectors: `${HOST}/api/v1/onboarding/list_directors`,
    getInvites: (application_uuid: string) =>
      `${HOST}/api/v1/onboarding/invitations?application_uuid=${application_uuid}`,
    invitePerson: `${HOST}/api/v1/onboarding/invitations`,
    submitDocuments: `${HOST}/api/v1/onboarding/submit_documents`,

    // insights cross-sell
    createInsightsApplication: `${HOST}/api/v1/insight_applications`,
    updateInsightsApplicationState: `${HOST}/api/v1/insight_applications/update-state`,
    addInsightsUser: `${HOST}/api/v1/insight_applications/add-user`,

    //collections
    getCollections: `${AWS_COLLECTIONS_HOST}/api/v1/collection_data`,
    getCollectionSummary: `${AWS_COLLECTIONS_HOST}/api/v1/collection_summary`,

    //Nach
    nach: `${HOST}/api/v1/nach`,
    updateBankAccount: (uuid: string) =>
      `${HOST}/api/v1/company_bank_accounts/${uuid}`,
    uploadNach: (nachId: string) => `${HOST}/api/v1/nach/${nachId}/upload`,
    confirmNach: (nachId: string) => `${HOST}/api/v1/nach/${nachId}/confirm`,

    // Repayments
    getRepayments: `${HOST}/api/borrower/v1/repayments`,
    acceptCashflowLockin: `${HOST}/api/borrower/v1/repayments/accept-cashflowlock`,

    //subscriptions
    getSubscriptions: (nbfc_id: string) =>
      `${HOST}/api/v1/subscriptions?nbfc_id=${nbfc_id}`,
    getBankAccounts: `${HOST}/api/v1/company_bank_accounts/get_bank_accounts_with_subscription`,
    createBankAccounts: `${HOST}/api/v1/company_bank_accounts`,
    initializeSubscription: (subscription_id: string) =>
      `${HOST}/api/v1/subscriptions/${subscription_id}/init`,
    initializeNachSubscription: `${HOST}/api/v1/subscriptions`,
    authorizeSubscription: (subscription_id: string) =>
      `${HOST}/api/v1/subscriptions/${subscription_id}/authorize`,

    // Add Pg integrations
    getPGIntegrations: (nbfc_id: string) =>
      `${HOST}/finance/payment_integrations?nbfc_id=${nbfc_id}`,
    addPGIntegrations: `${HOST}/finance/payment_integrations`,
    updatePGIntegration: `${HOST}/finance/payment_integrations/update_credentials`,
    getConnectionStatus: `${HOST}/api/v1/users/integration_status`,
    createRazorpayConfig: `${HOST}/api/v1/razorpay/create`,
    createRazorpayLinkedAccount: `${HOST}/api/v1/razorpay/account`,
    createRazorpayLinkedAccountV2: `${HOST}/api/v1/razorpay/account_v2`,
    getRazorpayLinkedAccounts: `${HOST}/api/v1/razorpay/pg_configuration`,
    reauthenticateRazorpayCreds: `${HOST}/api/v1/razorpay/reauthenticate`,

    // Vendors
    preApprovedVendors: `${HOST}/api/v1/vendors/eligible_gst_vendors`,
    pennyDrop: `${HOST}/api/v1/vendors/account_details`,
    getVendors: `${HOST}/api/v1/vendors`,
    addVendor: `${HOST}/api/v1/vendors`,

    //transactions
    getTransactions: (uuid: string) =>
      `${HOST}/api/borrower/v1/loans/${uuid}/statement`,
    getSingleTransaction: (transactionId: string) =>
      `${HOST}/api/v1/transactions/${transactionId}`,
    downloadStatement: (loanAccountId: string) =>
      `${HOST}/api/v1/loan_accounts/${loanAccountId}/download`,

    //payments
    getPayments: `${HOST}/api/v1/payments`,
    getSinglePayment: (paymentId: string) =>
      `${HOST}/api/v1/payments/${paymentId}`,
    createPayment: `${HOST}/api/v1/payments`,
    deletePayment: (uuid: string) => `${HOST}/api/v1/payments/${uuid}`,

    //drawdown
    getDrawdowns: `${HOST}/api/v1/drawdowns`,
    getDrawdownDetails: (uuid: string) => `${HOST}/api/v1/drawdowns/${uuid}`,
    drawdownApproval: (uuid: string) =>
      `${HOST}/api/v1/drawdowns/${uuid}/send_for_payment`,
    generateAddendum: (uuid: string) =>
      `${HOST}/api/v1/drawdowns/${uuid}/generate_addendum`,
    createAddendumSignRequest: `${HOST}/api/v1/signed_documents`,
    getActiveRequest: (uuid: string) =>
      `${HOST}/api/v1/signed_documents/${uuid}`,
    updateDigioStatus: `${HOST}/admin/signed_documents/update_status`,

    //loan account
    loanAccounts: `${HOST}/api/borrower/v1/loans`,
    loanAccountConnection: (uuid: string) =>
      `${HOST}/api/v1/loan_accounts/${uuid}/connections`,
    loanAccountCollectionProperties: (uuid: string) =>
      `${HOST}/api/borrower/v1/loans/${uuid}/collection-properties`,

    // Integrations
    getIntegrations: `${HOST}/api/v1/integrations`,
    createIntegration: `${HOST}/api/v1/integrations`,
    discardIntegration: `${HOST}/api/v1/integration_accounts`,
    updatePANIntegration: `${HOST}/api/v1/integrations/pan_integration`,
    updateGSTIntegration: `${HOST}/api/v1/integrations/gst_integration`,
    selectIntegrationAccount: (integrationId: string) =>
      `${HOST}/api/v1/integrations/${integrationId}`,
    getAllGSTNumbers: `${HOST}/api/v1/integrations/get_all_gst`,
    verifyFlipkart: `${HOST}/api/v1/integrations/flipkart/verify`,
    createFlipkartIntegration: `${HOST}/api/v1/integrations/flipkart_integration`,
    getIntegrationsRejection: (applicationUuid: string) =>
      `${HOST}/api/v1/product_event_threads/application_rejection_data?application_uuid=${applicationUuid}`,
    requestGstOtp: `${HOST}/api/v1/integrations/gst/request-otp`,
    validateGstOtp: `${HOST}/api/v1/integrations/gst/validate-otp`,

    //experian
    directorVerifyMobile: `${HOST}/api/v1/experian/enhance-match`,
    directorAuthenticateMobile: `${HOST}/api/v1/experian/authenticate-delivery-data`,

    //public
    documentTypes: `${HOST}/public/document-types`,
    getOfferDetails: (applicationId: string) =>
      `${HOST}/public/finance_applications/${applicationId}`,
    requestedDocumentTypes: (application_uuid: string) =>
      `${HOST}/api/v1/application_requested_document?application_id=${application_uuid}`,

    // resanction applications
    getResanctionApplications: `${HOST}/api/v1/resanction_applications`,

    // topup applications
    getTopupEligibility: `${HOST}/api/v1/company/topup-eligibility`,
    getTopupApplications: `${HOST}/api/v1/topup-applications`,
    createTopupApplication: `${HOST}/api/v1/company/topup-application`,

    // revenue sources
    getRevenueSources: `${HOST}/api/v1/revenue_source`,

    //ledger
    ledgerOverview: (uuid: string) =>
      `${HOST}/api/borrower/v1/loans/${uuid}/overview`,
    getLedger: (uuid: string) =>
      `${HOST}/api/borrower/v1/loans/${uuid}/payable`,
    getRevenueLedger: (uuid: string) =>
      `${HOST}/api/borrower/v1/loans/${uuid}/revenues`,

    //get all banks
    getAllBanks: `${HOST}/api/v1/banks`,
    bankIfsc: `${HOST}/api/v1/ifsc`,

    // finbit
    getConnectedBanks: `${HOST}/api/v1/bank_account_integration`,
    updateConnectedBankAccount: (bank_uuid: string) =>
      `${HOST}/api/v1/bank_account_integration/${bank_uuid}`,
    uploadBankStatement: `${HOST}/api/v1/bank_account_integration/upload_statement`,
    statement: (statement_uuid: string) =>
      `${HOST}/api/v1/bank_account_statement/${statement_uuid}`,
    processStatement: `${HOST}/api/v1/bank_account_integration/process_statements`,

    //co-borrower
    getBorrowers: (application_uuid: string) =>
      `${HOST}/api/borrower/v1/applications/${application_uuid}/borrowers`,
    updateBorrower: (application_uuid: string, borrower_uuid: string) =>
      `${HOST}/api/borrower/v1/applications/${application_uuid}/borrowers/${borrower_uuid}`,

    //adhar xml share code
    updateShareCode: `${HOST}/api/v1/application_documents`,

    //offer details
    topupList: (loan_account_uuid: string) =>
      `${HOST}/api/v1/topups/${loan_account_uuid}`,

    // neobank
    // accounts
    getVirtualAccounts: `${HOST}/api/borrower/v1/virtual-accounts`,
    getVaLetter: (account_uuid: string) =>
      `${NEOBANK_HOST}/api/v1/accounts/${account_uuid}/generate-va-letter`,
    ongoingTransfers: `${NEOBANK_HOST}/api/v1/transfers/ongoing`,
    companyVirtualAccounts: `${HOST}/api/v1/company_virtual_accounts`,

    // signatories
    getSignatories: (application_uuid: string) =>
      `${HOST}/api/v1/signatories?application_uuid=${application_uuid}`,
    createSignatory: (borrower_uuid: string) =>
      `${HOST}/api/v1/signatories?borrower_uuid=${borrower_uuid}`,
    eachSignatory: (signatory_uuid: string) =>
      `${HOST}/api/v1/signatories/${signatory_uuid}`,
    getSignatoriesKycRequests: `${HOST}/api/v1/kyc/digio/individual_kyc`,
    getSignatoriesKycDetails: `${HOST}/api/v1/kyc/digio/kyc_details`,

    //consent
    consents: `${HOST}/api/v1/consents`,
  },

  payments: {
    getIfscData: (code: string) => `https://ifsc.razorpay.com/${code}`,
    sendPaymentsAppLink: `${NEOBANK_HOST}/api/v1/payments/send_invite`,
    // transactions
    getTransactions: (accountNo: string) =>
      `${NEOBANK_HOST}/api/v1/ledgers/${accountNo}`,
    getStatement: `${NEOBANK_HOST}/api/v1/ledgers`,
    exportStatement: `${NEOBANK_HOST}/api/v1/payments/export`,
    bulkExportStatement: `${NEOBANK_HOST}/api/v1/bulk_payments/export`,
    collectionExportStatement: `${NEOBANK_HOST}/api/v2/ledgers/statement`,
    getBranchDetailsByIfsc: (ifscCode: string) =>
      `${HOST}/api/v1/ifsc/${ifscCode}`,
    getVaDetails: `${NEOBANK_HOST}/api/v1/accounts/`,

    // payments
    pennyDrop: `${NEOBANK_HOST}/api/v1/payees/account_details`,
    createSeller: `${NEOBANK_HOST}/api/v1/client/seller`,
    createPaymentMultiple: `${NEOBANK_HOST}/api/v1/payments/multiple`,
    createPaymentDirect: `${NEOBANK_HOST}/api/v1/payments/direct`,
    bulkPayments: `${NEOBANK_HOST}/api/v1/bulk_payments`,
    creditCardPaymentStatus: (order_id: string) =>
      `${NEOBANK_HOST}/api/v1/payments/composite_payment/${order_id}`,
    juspayCreditCardPaymentStatus: (order_id: string) =>
      `${NEOBANK_HOST}/api/v1/juspay/order_status?order_id=${order_id}`,
    initiatePayments: `${NEOBANK_HOST}/api/v1/payments/initiate_payment`,
    initiateBulkPayments: `${NEOBANK_HOST}/api/v1/bulk_payments/initiate_payment`,
    paymentSummary: `${NEOBANK_HOST}/api/v1/payments/summary`,
    showPayment: (payment_id: string) =>
      `${NEOBANK_HOST}/api/v1/payments/${payment_id}`,

    // invoices
    getInvoice: `${NEOBANK_HOST}/api/v1/invoices`,
    getInvoiceSummary: `${NEOBANK_HOST}/api/v1/invoices/summary`,
    createInvoice: `${NEOBANK_HOST}/api/v1/invoices`,
    editInvoice: `${NEOBANK_HOST}/api/v1/invoices/verify`,
    verifyInvoice: (invoice_id: string) =>
      `${NEOBANK_HOST}/api/v1/invoices/${invoice_id}/verify`,
    deleteInvoice: (invoice_id: string) =>
      `${NEOBANK_HOST}/api/v1/invoices/${invoice_id}`,
    markPaidInvoice: (invoice_id: string) =>
      `${NEOBANK_HOST}/api/v1/invoices/${invoice_id}/paid`,
    importTally: `${NEOBANK_HOST}/api/v1/invoices/import`,
    showInvoice: (invoice_id: string) =>
      `${NEOBANK_HOST}/api/v1/invoices/${invoice_id}`,

    // payouts
    getSinglePayouts: `${NEOBANK_HOST}/api/v1/payments`,
    getBulkPayouts: `${NEOBANK_HOST}/api/v1/bulk_payments`,
    showBulkPayment: (bulk_payment_id: string) =>
      `${NEOBANK_HOST}/api/v1/bulk_payments/${bulk_payment_id}`,
    deleteBulkPayment: (bulk_payment_id: string) =>
      `${NEOBANK_HOST}/api/v1/bulk_payments/${bulk_payment_id}`,
    getActiveCreditLine: `${HOST}/api/v1/credit_line/active_account`,

    // approvals
    getApprovals: `${NEOBANK_HOST}/api/v1/payments/pending_approvals`,
    approveAndPay: `${NEOBANK_HOST}/api/v1/payments/approve_and_pay`,
    approveAndRejectPayments: `${NEOBANK_HOST}/api/v1/payments/`,
    approveAndRejectBulkPayments: `${NEOBANK_HOST}/api/v1/bulk_payments/`,
    sendForApprovalInvoices: `${NEOBANK_HOST}/api/v1/invoices/send_for_approval/`,

    // history
    getPaymentHistory: `${NEOBANK_HOST}/api/v1/payments`,

    //payee
    payee: `${NEOBANK_HOST}/api/v1/payees/`,

    // Vendors
    preApprovedVendors: `${HOST}/api/v1/vendors/eligible_gst_vendors`,
    getVendors: `${NEOBANK_HOST}/api/v1/vendors`,
    getSellers: `${NEOBANK_HOST}/api/v1/client/seller`,
    getVendor: (vendor_id: string) =>
      `${NEOBANK_HOST}/api/v1/vendors/${vendor_id}`,
    getSellerDetails: (seller_id: string, status: string) =>
      `${NEOBANK_HOST}/api/v1/client/seller/${status}/${seller_id}`,
    deleteVendor: (vendor_id: string) =>
      `${NEOBANK_HOST}/api/v1/vendors/${vendor_id}`,
    deleteSeller: (seller_id: string, status: string) =>
      `${NEOBANK_HOST}/api/v1/client/seller/destroy/${status}/${seller_id}`,
    addVendor: `${NEOBANK_HOST}/api/v1/vendors`,
    editVendor: (vendor_id: string) =>
      `${NEOBANK_HOST}/api/v1/vendors/${vendor_id}`,
    editSeller: (seller_id: string, status: string) =>
      `${NEOBANK_HOST}/api/v1/client/seller/${status}/${seller_id}`,
    getVendorBankAccounts: `${NEOBANK_HOST}/api/v1/vendor_bank_accounts`,
    createVendorBankAccount: `${NEOBANK_HOST}/api/v1/vendor_bank_accounts`,
    markPrimaryVendorBankAccount: (vendor_bank_account_uuid: string) =>
      `${NEOBANK_HOST}/api/v1/vendor_bank_accounts/${vendor_bank_account_uuid}}/mark_primary`,
    getVendorBusiness: `${NEOBANK_HOST}/api/v1/vendor_businesses`,
    createVendorBusiness: `${NEOBANK_HOST}/api/v1/vendor_businesses`,
    updateVendorBusiness: (vendorBusinessUuid: string) =>
      `${NEOBANK_HOST}/api/v1/vendor_businesses/${vendorBusinessUuid}`,
    getVendorCustomer: `${NEOBANK_HOST}/api/v1/vendor_customers`,
    createVendorCustomer: `${NEOBANK_HOST}/api/v1/vendor_customers`,
    attachVendorInvoice: `${NEOBANK_HOST}/api/v1/invoices/`,
    attachVendorPayment: `${NEOBANK_HOST}/api/v1/payments/`,
    sendForApprovalPayment: `${NEOBANK_HOST}/api/v1/payments/send_for_approval/`,
    sendForApprovalBulkPayment: `${NEOBANK_HOST}/api/v1/bulk_payments/send_for_approval/`,
    getVendorPaidInvoices: (vendor_id: string) =>
      `${NEOBANK_HOST}/api/v1/vendors/${vendor_id}/vendor_payments/`,
    getVendorInvoices: (vendor_id: string) =>
      `${NEOBANK_HOST}/api/v1/vendors/${vendor_id}/pending_invoices`,
    getVendorMonthlyPaidAmount: (vendor_id: string) =>
      `${NEOBANK_HOST}/api/v1/vendors/${vendor_id}/payment_summary`,
    initiateVendorPayment: (vendor_id: string) =>
      `${NEOBANK_HOST}/api/v1/vendors/${vendor_id}/invoices_for_payment`,
    createVendorPayments: `${NEOBANK_HOST}/api/v1/vendor_payments`,
    vendorAction: (vendor_id: string) =>
      `${NEOBANK_HOST}/api/v1/vendors/${vendor_id}`,
    sendForApprovalVendorPayments: `${NEOBANK_HOST}/api/v1/vendor_payments/send_for_approval/`,

    // Categories
    getVendorCategories: `${NEOBANK_HOST}/api/v1/categories`,
    createVendorCategory: `${NEOBANK_HOST}/api/v1/categories`,

    //invite
    invite: `${NEOBANK_HOST}/api/v1/invites`,
    publicInvite: `${NEOBANK_HOST}/public/`,

    // Uncomment below on local to get vendor list from postman mock server. Mock data is added to the company account
    /* preApprovedVendors: `https://b5832971-eb41-430c-bf1f-1c4729eb5fb7.mock.pstmn.io/api/v1/vendors/eligible_gst_vendors`,
    getVendors: `https://b5832971-eb41-430c-bf1f-1c4729eb5fb7.mock.pstmn.io/api/v1/vendors`,  */

    // cashback
    getBulkTemplate: `${NEOBANK_HOST}/api/v1/bulk_payments/template`,
    getCashback: `${NEOBANK_HOST}/api/v1/cashbacks`,
    createAccount: `${NEOBANK_HOST}/api/v1/accounts`,

    //invitation and admin
    invitaion: `${HOST}/api/v1/onboarding/invitations`,
    resendInvitation: (invite_uuid: string) =>
      `${HOST}/api/v1/onboarding/invitations/${invite_uuid}/resend_email`,
    deleteInvitation: (invite_uuid: string) =>
      `${HOST}/api/v1/onboarding/invitations/${invite_uuid}?application_type=payments`,

    //application users
    applicationUser: `${HOST}/api/v1/application_users`,
    sendWaitListSlackMessage: `${HOST}/public/payments_applications`,

    //connect tally
    tallySummary: `${NEOBANK_HOST}/api/v1/tally_bills/summary`,
    tallyInstaller: `${NEOBANK_HOST}/api/v1/tally_bills/installer`,

    //credit notes
    createCreditNote: `${NEOBANK_HOST}/api/v1/credit_notes`,
    deleteCreditNote: (creditNoteUuid: string) =>
      `${NEOBANK_HOST}/api/v1/credit_notes/${creditNoteUuid}`,
    // pay via card
    getSavedCards: `${NEOBANK_HOST}/api/v2/saved_cards`,
    createJuspayTransaction: `${JUSPAY_HOST}/txns`,
    createSavedCard: `${NEOBANK_HOST}/api/v1/saved_cards`,
    getJuspayOrderStatus: (order_id: string) =>
      `${NEOBANK_HOST}/api/v1/juspay/order_status?order_id=${order_id}`,
    getCreditCardSettlementDate: `${NEOBANK_HOST}/api/v1/payments/credit_card/settlement_date`,
    getCardSubtype: `${NEOBANK_HOST}/public/common/verify/card`,

    // payment requests
    getPaymentRequests: `${NEOBANK_HOST}/api/v1/payment_requests`,
    getPaymentRequestInfo: (uuid: string) =>
      `${NEOBANK_HOST}/api/v1/payment_requests/${uuid}`,
    createPaymentRequests: `${NEOBANK_HOST}/api/v1/payment_requests`,
    getPaymentsRequestSummary: `${NEOBANK_HOST}/api/v1/payment_requests/summary`,
    createJuspayPaymentLinkOrder: `${NEOBANK_HOST}/public/payment_requests/payment_links/pay`,
    getPdcPaymentRequests: `${NEOBANK_HOST}/api/v1/post_dated_cheques`,
    markPaymentRequestPaid: (uuid: string) =>
      `${NEOBANK_HOST}/api/v1/payment_requests/${uuid}/paid`,
    sendReminder: (uuid: string) =>
      `${NEOBANK_HOST}/api/v1/payment_requests/${uuid}/reminder`,
    createApplication: `${HOST}/api/v1/applications`,
    getSinglePayment: (uuid: string) =>
      `${NEOBANK_HOST}/api/v1/payments/${uuid}`,
    updatePaymentLinkStatus: `${NEOBANK_HOST}/public/payment_requests/payment_links/status`,
    getUserIpAddress: "https://api.ipify.org?format=json",

    // buyers
    createBuyer: `${NEOBANK_HOST}/api/v1/client/buyer`,
    searchBuyer: `${NEOBANK_HOST}/api/v1/client/search/buyer`,
    createPdcRequest: `${NEOBANK_HOST}/api/v1/post_dated_cheques`,

    // buyer credit score
    processfinbudExpriean: `${HOST}/public/credit_score_report/finbud/fetch_report`,

    approvePaymentLink: (paymentLinkCode: string) =>
      `${NEOBANK_HOST}/public/upi_payment/${paymentLinkCode}/approve`,
    uploadProofUpiPaymentLink: (paymentLinkCode: string) =>
      `${NEOBANK_HOST}/public/upi_payment/${paymentLinkCode}/upload_proof`,
    ewsAlerts: `${HOST}/api/v1/nbfcs/ews_alerts_summary`,
  },
};

export {
  AWS_COLLECTIONS_HOST,
  CASHFREE_SDK,
  CURRENT_ENV,
  HOST,
  KYC_SDK,
  MIXPANEL_TOKEN,
  NEOBANK_HOST,
  WEBSITE_HOST,
};
