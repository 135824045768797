import PageNotFound from "@/pages/page_not_found/page_not_found";
import { auth } from "@/wrappers";
import { lazy } from "react";
import { Navigate, redirect, RouteObject } from "react-router-dom";
import { LazyELement, errorElement } from "./utils";
import SsoAuthenticator from "@/pages/auth/sso_authenticator";

const AuthLayout = lazy(() => import("@/layouts/auth_layout/auth_layout"));
const Login = lazy(() => import("@/pages/auth/login"));
const OTPVerify = lazy(() => import("@/pages/auth/signup/otp_verify"));
const PhoneVerification = lazy(
  () => import("@/pages/auth/signup/phone_verification")
);
const Signup = lazy(() => import("@/pages/auth/signup/signup"));
const SetPassword = lazy(() => import("@/pages/auth/login/set_password"));
const ChangePassword = lazy(
  () => import("@/pages/auth/forgot_password/change_password")
);
const ForgotPassword = lazy(() => import("@/pages/auth/forgot_password"));

const GhostAuthenticator = lazy(
  () => import("@/pages/auth/ghost_authenticator/ghost_authenticator")
);

const MagicLinkAuthenticator = lazy(
  () => import("@/pages/auth/magic_link_authenticator")
);

const routes: RouteObject = {
  path: "/auth",
  element: (
    <LazyELement>
      <AuthLayout />
    </LazyELement>
  ),
  loader: async () => {
    const redirect_url = auth();
    if (redirect_url) {
      return redirect(redirect_url);
    }
    return null;
  },
  errorElement: <PageNotFound />,
  children: [
    { index: true, element: <Navigate to="/auth/login" /> },
    {
      path: "sso",
      element: (
        <LazyELement>
          <SsoAuthenticator />
        </LazyELement>
      ),
    },
    {
      path: "ghost",
      element: (
        <LazyELement>
          <GhostAuthenticator />
        </LazyELement>
      ),
    },
    {
      path: "magic",
      element: (
        <LazyELement>
          <MagicLinkAuthenticator />
        </LazyELement>
      ),
    },
    {
      path: "web",
      lazy: () => import("@/pages/auth/web_link_authenticator"),
      errorElement,
    },
    {
      path: "changePassword",
      element: (
        <LazyELement>
          <ChangePassword />
        </LazyELement>
      ),
    },
    {
      path: "forgot_password",
      element: (
        <LazyELement>
          <ForgotPassword />
        </LazyELement>
      ),
    },
    {
      path: "login",
      element: (
        <LazyELement>
          <Login />
        </LazyELement>
      ),
    },
    {
      path: "otp_verify",
      element: (
        <LazyELement>
          <OTPVerify />
        </LazyELement>
      ),
    },
    {
      path: "phone_verification",
      element: (
        <LazyELement>
          <PhoneVerification />
        </LazyELement>
      ),
    },
    {
      path: "signup",
      element: (
        <LazyELement>
          <Signup />
        </LazyELement>
      ),
    },
    {
      path: "set_password",
      element: (
        <LazyELement>
          <SetPassword />
        </LazyELement>
      ),
    },
  ],
};

export default routes;
