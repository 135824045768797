import { Loader } from "@/ui-kit";
import { Suspense } from "react";

import PageNotFound from "@/pages/page_not_found/page_not_found";

export const LazyELement = ({ children }: { children: JSX.Element }) => (
  <Suspense fallback={<Loader />}>{children}</Suspense>
);

export const errorElement = <PageNotFound />;
