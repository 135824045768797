import { pluralize } from "./common";

function convertNumberToWords(amount) {
  let value = "";
  if (amount == 0) return "Zero";
  var words = [];
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  var atemp = amount.split(".");
  var number = atemp[0].split(",").join("");
  var n_length = number.length;
  var words_string = "";
  if (n_length <= 9) {
    var n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    var received_n_array = [];
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    value = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + " ";
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += "Hundred and ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }

    words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}

export function convertNumberToDollars(amount) {
  if (amount == 0) return "Zero";

  let words = [];
  words[0] = "zero";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  words[100] = "One Hundred";
  words[200] = "Two Hundred";
  words[300] = "Three Hundred";
  words[400] = "Four Hundred";
  words[500] = "Five Hundred";
  words[600] = "Six Hundred";
  words[700] = "Seven Hundred";
  words[800] = "Eight Hundred";
  words[900] = "Nine Hundred";

  amount = amount.toString();
  var atemp = amount.split(".");
  var number = atemp[0].split(",").join("");
  var n_length = number.length;
  var words_string = "";
  if (n_length <= 11) {
    var n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    var received_n_array = [];
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 11 - n_length, j = 0; i < 11; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 11; i++, j++) {
      if (i == 0 || i == 3 || i == 6 || i == 9) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (var i = 0; i < 11; i++) {
      if (i == 0 || i == 3 || i == 6 || i == 9) {
        value = n_array[i] * 10;
      } else if (i == 2 || i == 5 || i == 8) {
        value = n_array[i] * 100;
      } else {
        value = n_array[i];
      }

      if (value != 0) {
        words_string += words[value] + " ";
      }
      if (i == 1 && value != 0 && n_array[i - 1] > 0) {
        words_string += "Billion ";
      } else if (i == 1 && value != 0) {
        words_string += "Biillion ";
      }
      if (i == 4 && value == 0 && (n_array[i - 1] > 0 || n_array[i - 2] > 0)) {
        words_string += "Million ";
      } else if (i == 4 && value != 0) {
        words_string += "Million ";
      }
      if (i == 7 && value == 0 && (n_array[i - 1] > 0 || n_array[i - 2] > 0)) {
        words_string += "Thousand ";
      } else if (i == 7 && value != 0) {
        words_string += "Thousand ";
      }
    }
    words_string = words_string.split(" ").join(" ");
  }
  return words_string;
}

export function exponentialValue(value) {
  let scaled_value = Math.floor(Math.exp(value));
  let multiplier = scaled_value.toString().length;
  multiplier = Math.pow(10, multiplier - 1);
  return Math.round(scaled_value / multiplier) * multiplier;
}

export function formatAmountTowords(amount, prefix = "Rs. ", fixedDigits = 2) {
  const value = parseFloat(amount.toString());
  let formattedValue = amount.toString();

  if (value >= 10000000) {
    const formatted = (value / 10000000).toFixed(fixedDigits);
    const formattedNumber = parseInt(formatted);
    formattedValue = `${formatted} ${pluralize("Crore", formattedNumber)}`;
  } else if (value >= 100000) {
    const formatted = (value / 100000).toFixed(fixedDigits);
    const formattedNumber = parseInt(formatted);
    formattedValue = `${formatted} ${pluralize("Lakh", formattedNumber)}`;
  } else if (value >= 1000) {
    formattedValue = (value / 1000).toFixed(fixedDigits) + " K";
  }
  return `${prefix}${formattedValue}`;
}

export default convertNumberToWords;

export const add_prefix_zero = (value) =>
  value < 10 ? `0${value}` : `${value}`;
