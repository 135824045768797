import { Navigate, RouteObject } from "react-router-dom";

const financeRoutes: RouteObject = {
  path: "finance",
  children: [
    {
      index: true,
      element: <Navigate to="apply" />,
    },
    {
      path: "indicative-offers",
      lazy: () => import("@/pages/finance/pages/indicative_offers"),
    },
    {
      path: "get-started",
      lazy: () =>
        import("@/pages/finance/pages/los/modules/get_started/get_started"),
    },
    {
      path: "consents",
      lazy: () => import("@/pages/finance/pages/los/gst_consents"),
    },
    {
      path: "apply",
      lazy: () => import("@/pages/finance/pages/los"),
    },
    {
      path: ":application_id/apply",
      lazy: () => import("@/pages/finance/pages/los"),
    },
    {
      path: "connect",
      lazy: () => import("@/pages/finance/pages/los"),
    },
    {
      path: ":application_id/connect",
      lazy: () => import("@/pages/finance/pages/los"),
    },
    {
      path: "set-password",
      lazy: () => import("@/pages/finance/pages/set_password"),
    },
  ],
};

export default financeRoutes;
