import {
  APPS,
  SALE_APPS,
  GOOGLE_ANALYTICS,
} from "@/shared-components/integrations/integrations_constants";

export function addUserAttribute(uuid, data) {
  if (window?.hj) {
    window.hj("identify", uuid, data);
  }
}

export function getUserIntegrations(integrations) {
  let salesIntegrationSet = new Set();
  let marketingIntegrationSet = new Set();
  let analyticsIntegrationSet = new Set();
  integrations?.forEach((element) => {
    if (element.integration_accounts.length < 1) return;
    if (SALE_APPS.includes(element.integration_name))
      salesIntegrationSet.add(element.integration_name);
    else if (APPS.includes(element.integration_name))
      marketingIntegrationSet.add(element.integration_name);
    else if (GOOGLE_ANALYTICS.includes(element.integration_name))
      analyticsIntegrationSet.add(element.integration_name);
  });
  let userIntegrations = {
    sales: Array.from(salesIntegrationSet),
    marketing: Array.from(marketingIntegrationSet),
    analytic: Array.from(analyticsIntegrationSet),
  };

  return userIntegrations;
}

export const getIntegrationAccountCount = (integrations) => {
  let integrationAccountCount = 0;

  integrations.forEach((integration) => {
    integrationAccountCount += integration.integration_accounts.length;
  });

  return integrationAccountCount;
};

export const getErrorMessage = (error = {}) => {
  let errorMsg =
    error?.fields?.length > 0 ? error?.fields[0]?.issue : undefined;
  return errorMsg;
};

export function getAssetURL(path) {
  return `https://d1pejaumv7umok.cloudfront.net/${path}`;
}
