import { proxy } from "valtio";
import { MarketplaceType } from "@/swr/auth/types";

const initialState = {
  uuid: undefined,
  otpUUID: undefined,
  phoneVerified: undefined,
  maskedPhone: undefined,
  phone: undefined,
  marketplaceClientId: undefined,
  marketplace: undefined,
  registeredMarketplaces: undefined,
};

export const store = proxy<{
  uuid: string | undefined;
  otpUUID: string | undefined;
  phoneVerified: boolean | undefined;
  passwordEnabled: boolean | undefined;
  maskedPhone: string | undefined;
  phone: string | undefined;
  marketplaceClientId: string | undefined;
  marketplace: string | undefined;
  userRegisteredMarketplaces: MarketplaceType[] | undefined;
}>(initialState);

export const user_store = store;

export const setUUID = ({ uuid }: { uuid: string }) => {
  store.uuid = uuid;
};

export const setOTPUUID = ({
  uuid,
  phoneVerified,
  maskedPhone,
  passwordEnabled,
}: {
  uuid: string;
  phoneVerified: boolean | undefined;
  maskedPhone: string;
  passwordEnabled: boolean;
}) => {
  store.otpUUID = uuid;
  store.phoneVerified = phoneVerified;
  store.maskedPhone = maskedPhone;
  store.passwordEnabled = passwordEnabled;
};

export const changeOTPUUID = ({ uuid }: { uuid: string }) => {
  store.otpUUID = uuid;
};

export const setPhone = ({ phone }: { phone: string }) => {
  store.phone = phone;
};

export const reset = () => {
  Object.keys(initialState).forEach((key) => (store[key] = undefined));
};

export const setMarketplaceClientId = (marketplaceClientId: string) => {
  store.marketplaceClientId = marketplaceClientId;
};

export const setUserRegisteredMarketplaces = (
  marketplaceList: MarketplaceType[]
) => {
  store.userRegisteredMarketplaces = marketplaceList;
};
