import ReactDOM from "react-dom/client";
import React from "react";
import * as Sentry from "@sentry/react";
import { isProduction } from "@/ve-utils/common";

import App from "./App";

import { apiCallSWR } from "./swr/utils";
import { SWRConfig } from "swr";

import "antd/dist/antd.less";

if (isProduction()) {
  Sentry.init({
    dsn: "https://95bb0b37a22e441a966a6f04974db383@o359377.ingest.sentry.io/4504778613194752",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <SWRConfig
      value={{
        fetcher: apiCallSWR,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        // provider: () => SWRCache, // commenting for now
      }}
    >
      <App />
    </SWRConfig>
  </React.StrictMode>
);
